import createSvgIcon from '../Utils/CreateSvgIcon';

export const ThreeSixty = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M6.924 8.018c.244.052.508.078.792.078.292 0 .556-.036.792-.108.236-.076.434-.182.594-.318.164-.136.288-.296.372-.48.084-.184.126-.384.126-.6 0-.344-.094-.626-.282-.846-.184-.224-.446-.368-.786-.432l.906-.87v-.906H6.42v1.062h1.44l-.942.912.306.702c.1-.032.236-.048.408-.048.188 0 .332.036.432.108a.35.35 0 0 1 .156.306.363.363 0 0 1-.168.318c-.108.076-.262.114-.462.114a2.59 2.59 0 0 1-1.17-.288L6.306 7.79c.168.1.374.176.618.228Zm3.81-.372c.323.3.774.45 1.35.45.344 0 .646-.068.905-.204.264-.136.466-.32.606-.552.144-.236.216-.502.216-.798 0-.28-.063-.528-.191-.744a1.283 1.283 0 0 0-.504-.498c-.213-.12-.45-.18-.714-.18a.987.987 0 0 0-.516.126 4.91 4.91 0 0 1 .623-.798c.26-.276.586-.572.979-.888l-1.608-.048a4.844 4.844 0 0 0-1.2 1.38c-.288.508-.432 1.008-.432 1.5 0 .536.162.954.486 1.254Zm1.637-.762a.439.439 0 0 1-.324.126.451.451 0 0 1-.324-.126.451.451 0 0 1-.126-.324c0-.132.043-.24.127-.324a.451.451 0 0 1 .323-.126c.133 0 .24.042.324.126a.439.439 0 0 1 .127.324.451.451 0 0 1-.127.324Zm3.96-.258c-.06.184-.154.276-.282.276-.128 0-.224-.092-.288-.276-.06-.184-.09-.47-.09-.858s.03-.674.09-.858c.064-.184.16-.276.288-.276.128 0 .222.092.282.276.064.184.096.47.096.858s-.032.674-.096.858Zm-1.626.858c.32.408.768.612 1.344.612.576 0 1.022-.204 1.338-.612.32-.408.48-.98.48-1.716s-.16-1.308-.48-1.716c-.316-.408-.762-.612-1.338-.612-.576 0-1.024.204-1.344.612-.316.408-.474.98-.474 1.716s.158 1.308.474 1.716ZM20 13.99c0 .21-.223.941-1.822 1.739-1.07.534-2.51.95-4.178 1.14v2.011c4.564-.462 8-2.476 8-4.89C22 11.234 17.523 9 12 9S2 11.234 2 13.99c0 2.206 2.87 4.078 6.849 4.737l-.893.892 1.414 1.415 2.664-2.664.707-.707-.707-.707-2.664-2.663-1.414 1.414 1.01 1.01c-1.23-.22-2.302-.568-3.144-.988C4.223 14.93 4 14.2 4 13.989c0-.21.223-.94 1.822-1.738C7.3 11.514 9.482 11 12 11c2.519 0 4.7.514 6.178 1.251C19.777 13.05 20 13.78 20 13.99Z"
    fillRule="evenodd"
  />,
  'ThreeSixty',
);
