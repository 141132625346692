import createSvgIcon from '../Utils/CreateSvgIcon';

export const EmissionStickerE = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.0418" cy="12.0418" r="12.0418" fill="#196F3E" />
    <circle cx="12" cy="12" r="8.5" fill="white" />
    <circle cx="12" cy="12" r="8" fill="white" />
    <path
      d="M9.30569 7.64L14.8277 7.64V9.048H10.9337L10.9337 11.05H14.3767L14.3767 12.447L10.9337 12.447V14.592L14.9817 14.592V16L9.30569 16L9.30569 7.64Z"
      fill="#196F3E"
    />
  </svg>,
  'EmissionStickerE',
);
