/* eslint-disable @typescript-eslint/naming-convention */

import createSvgIcon from '../Utils/CreateSvgIcon';

export const SUV = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M33.1459 8.5H32.3032L31.8649 9.21972L25.9078 19H15.5C12.4624 19 10 21.4624 10 24.5V34.5V36H11.5H16.086C16.7616 39.1452 19.5178 41.5 22.815 41.5C26.1123 41.5 28.8685 39.1452 29.5441 36H53.4741C54.1497 39.1452 56.9059 41.5 60.2032 41.5C63.6942 41.5 66.5786 38.8604 67.0295 35.4378L72.9835 33.4207L74.0022 33.0756V32V23V22.5813L73.7853 22.2231L65.914 9.22309L65.4762 8.5H64.6309H33.1459ZM60.2032 27.5C56.9059 27.5 54.1497 29.8548 53.4741 33H29.5441C28.8685 29.8548 26.1123 27.5 22.815 27.5C19.5178 27.5 16.7616 29.8548 16.086 33H13V24.5C13 23.1193 14.1193 22 15.5 22H26.7505H27.5932L28.0316 21.2803L33.9886 11.5H63.7856L71.0022 23.4187V30.9244L66.7631 32.3606C65.8736 29.541 63.2729 27.5 60.2032 27.5ZM26.7026 34.5C26.7026 36.7551 24.9165 38.5 22.815 38.5C20.7136 38.5 18.9275 36.7551 18.9275 34.5C18.9275 32.2449 20.7136 30.5 22.815 30.5C24.9165 30.5 26.7026 32.2449 26.7026 34.5ZM60.2032 38.5C62.3046 38.5 64.0908 36.7551 64.0908 34.5C64.0908 32.2449 62.3046 30.5 60.2032 30.5C58.1017 30.5 56.3156 32.2449 56.3156 34.5C56.3156 36.7551 58.1017 38.5 60.2032 38.5ZM34.622 22L62.1714 22L62.1714 19L34.622 19V22Z"
    fillRule="evenodd"
  />,
  'SUV',
  '0 0 84 84',
);
