import createSvgIcon from '../Utils/CreateSvgIcon';

export const YoutubeLight = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2L16 2L16 16H2V2ZM0 0L2 0L16 0L18 0V2L18 16L18 18H16H2H0V16V2V0ZM13 9L7 13V5L13 9Z"
      fill="#303030"
    />
  </svg>,
  'YoutubeLight',
);
