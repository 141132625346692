import createSvgIcon from '../Utils/CreateSvgIcon';

export const SuccessTickCircle = createSvgIcon(
  <>
    <circle cx="12" cy="12" fill="#FDEEEE" r="10" transform="rotate(90 12 12)" />
    <circle cx="12" cy="12" fill="#1F8B4D" r="10" />
    <path d="m6.5 11.5 3.667 3.5L17.5 8" fill="none" stroke="#fff" strokeWidth="2" />
  </>,
  'SuccessTickCircle',
);
