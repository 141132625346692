import createSvgIcon from '../Utils/CreateSvgIcon';

export const CancelCircle = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" fill="#838383" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 13.4142L15.5356 16.9497L16.9498 15.5355L13.4143 12L16.9498 8.46447L15.5356 7.05025L12.0001 10.5858L8.46454 7.05025L7.05032 8.46447L10.5859 12L7.05032 15.5355L8.46454 16.9497L12.0001 13.4142Z"
      fill="white"
    />
  </svg>,
  'CancelCircle',
);
