import createSvgIcon from '../Utils/CreateSvgIcon';

export const Spain = createSvgIcon(
  <>
    <path d="M0 13h20V3H0v10Z" fill="#FAC361" />
    <path d="M0 12h20v4H0zM0 0h20v4H0z" fill="#C7270A" />
  </>,
  'Spain',
  '-2 -4 24 24',
);
