import createSvgIcon from '../Utils/CreateSvgIcon';

export const FinanceEuro = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 5L18 5V3L3 3L3 5Z" fill="#303030" />
    <path
      d="M15.93 20.328C16.7047 20.9253 17.6987 21.224 18.912 21.224C19.9013 21.224 20.76 21.028 21.488 20.636L21.194 18.676C20.5127 19.0027 19.8687 19.166 19.262 19.166C18.114 19.166 17.3487 18.6713 16.966 17.682L20.116 17.682V16.296L16.672 16.296C16.6627 16.24 16.658 16.0767 16.658 15.806C16.658 15.526 16.6627 15.3533 16.672 15.288L20.116 15.288L20.116 13.902L16.938 13.902C17.3207 12.9127 18.044 12.418 19.108 12.418C19.7893 12.418 20.4427 12.572 21.068 12.88L21.362 10.906C20.6713 10.542 19.85 10.36 18.898 10.36C17.7687 10.36 16.8073 10.6727 16.014 11.298C15.2207 11.9233 14.684 12.7913 14.404 13.902H12.92V15.288H14.194C14.1847 15.3533 14.18 15.5353 14.18 15.834V16.296H12.92V17.682H14.362C14.6327 18.8393 15.1553 19.7213 15.93 20.328Z"
      fill="#303030"
    />
    <path d="M16 9L3 9L3 7L16 7L16 9Z" fill="#303030" />
    <path d="M3 13L12 13V11L3 11L3 13Z" fill="#303030" />
    <path d="M10 17H3L3 15L10 15L10 17Z" fill="#303030" />
    <path d="M3 21H10V19H3L3 21Z" fill="#303030" />
  </svg>,
  'FinanceEuro',
);
