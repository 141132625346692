import createSvgIcon from '../Utils/CreateSvgIcon';

export const OfferLeft = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M4.19317 12L6.89317 6L20 6L20 18L6.89317 18L4.19317 12ZM2 12L5.6 20L20 20H22V18L22 6V4H20L5.6 4L2 12ZM9.46328 12.0666C10.2635 12.281 11.086 11.8061 11.3004 11.0059C11.5148 10.2057 11.0399 9.38321 10.2397 9.1688C9.43954 8.95439 8.61703 9.42926 8.40262 10.2295C8.18821 11.0297 8.66308 11.8522 9.46328 12.0666ZM17.8031 13.7836C17.5886 14.5838 16.7661 15.0586 15.9659 14.8442C15.1657 14.6298 14.6909 13.8073 14.9053 13.0071C15.1197 12.2069 15.9422 11.732 16.7424 11.9465C17.5426 12.1609 18.0175 12.9834 17.8031 13.7836ZM13.6871 9.09389L10.6871 14.29L12.4192 15.29L15.4192 10.0939L13.6871 9.09389Z"
    fillRule="evenodd"
  />,
  'OfferLeft',
);
