/* eslint-disable @typescript-eslint/naming-convention */

import createSvgIcon from '../Utils/CreateSvgIcon';

export const CO2 = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M18.317 13.975 18.16 14h-2.325c-.348-1.366.014-2.263.135-2.445l.555-.832-1.664-1.11-.555.832c-.42.631-.809 1.917-.516 3.555h-1.015c-.347-1.366.014-2.263.136-2.445l.555-.832-1.664-1.11-.555.832c-.42.631-.81 1.917-.516 3.555H9.716c-.347-1.366.014-2.263.136-2.445l.555-.832-1.665-1.11-.554.832c-.42.631-.81 1.917-.516 3.555H5.84l-.157-.025a2.001 2.001 0 0 1-.145-3.922l1.277-.3.233-1.292A3.001 3.001 0 0 1 12.039 6.8l.96.89 1.201-.524a2.002 2.002 0 0 1 2.75 1.383l.28 1.221 1.221.28a2.002 2.002 0 0 1-.134 3.925ZM14.37 16h-1.034c.213 1.953-.93 3.398-1.678 3.958l-.8.6-1.2-1.6.8-.6c.398-.298 1.077-1.162.853-2.358H10.28c.209 1.928-.958 3.259-1.68 3.8l-.8.6-1.2-1.6.8-.6c.422-.317 1.07-1.06.854-2.2H5.398a3.517 3.517 0 0 1-.026-.049 4.001 4.001 0 0 1-.292-7.845 5.002 5.002 0 0 1 8.32-2.773 4.002 4.002 0 0 1 5.5 2.768 4.001 4.001 0 0 1-.272 7.85 3.315 3.315 0 0 1-.026.049h-2.206c.213 1.953-.93 3.398-1.678 3.958l-.8.6-1.2-1.6.8-.6c.398-.298 1.077-1.162.853-2.358Z"
    fillRule="evenodd"
  />,
  'CO2',
);
