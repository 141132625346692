import createSvgIcon from '../Utils/CreateSvgIcon';

export const Saloon = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M35.6057 8.5H34.9886L34.5501 8.9343L24.097 19.2883L16.6584 20.1284C12.866 20.5566 10 23.7645 10 27.581V34.5V36H11.5H19.0379C19.7136 39.1452 22.4696 41.5 25.7668 41.5C29.064 41.5 31.82 39.1452 32.4956 36H53.474C54.1497 39.1452 56.9057 41.5 60.2029 41.5C63.5001 41.5 66.2561 39.1452 66.9317 36H72.5022H74.0022V34.5V25.1996C74.0022 22.7688 72.4064 20.6262 70.0774 19.93L65.1215 18.4485L59.5176 9.22136L59.0795 8.5H58.2355H35.6057ZM66.9317 33H71.0022V25.1996C71.0022 24.0947 70.2768 23.1208 69.2182 22.8043L63.7094 21.1576L63.1564 20.9923L62.8569 20.4991L57.3916 11.5H36.2228L25.8383 21.7861L25.4684 22.1525L24.9511 22.2109L16.995 23.1094C14.7196 23.3664 13 25.2911 13 27.581V33H19.0379C19.7136 29.8548 22.4696 27.5 25.7668 27.5C29.064 27.5 31.82 29.8548 32.4956 33H53.474C54.1497 29.8548 56.9057 27.5 60.2029 27.5C63.5001 27.5 66.2561 29.8548 66.9317 33ZM35.001 22H57.001V19H35.001V22ZM25.7668 38.5C27.868 38.5 29.6541 36.7552 29.6541 34.5C29.6541 32.2448 27.868 30.5 25.7668 30.5C23.6656 30.5 21.8794 32.2448 21.8794 34.5C21.8794 36.7552 23.6656 38.5 25.7668 38.5ZM64.0902 34.5C64.0902 36.7552 62.3041 38.5 60.2029 38.5C58.1017 38.5 56.3155 36.7552 56.3155 34.5C56.3155 32.2448 58.1017 30.5 60.2029 30.5C62.3041 30.5 64.0902 32.2448 64.0902 34.5Z"
    fillRule="evenodd"
  />,
  'Saloon',
  '0 0 84 84',
);
