import createSvgIcon from '../Utils/CreateSvgIcon';

export const Van = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M42.0353 8.5C42.0353 8.22386 42.2591 8 42.5353 8H70.0314C70.3076 8 70.5314 8.22386 70.5314 8.5V31H64.7088C63.4247 29.4715 61.4992 28.5 59.3467 28.5C57.1942 28.5 55.2686 29.4715 53.9846 31H42.0353V8.5ZM52.5078 34H40.5353H39.0353V32.5V8.5C39.0353 6.567 40.6023 5 42.5353 5H70.0314C71.9644 5 73.5314 6.567 73.5314 8.5V32.5V34H72.0314H66.1856C66.2911 34.4832 66.3467 34.9851 66.3467 35.5C66.3467 39.366 63.2127 42.5 59.3467 42.5C55.4807 42.5 52.3467 39.366 52.3467 35.5C52.3467 34.9851 52.4023 34.4832 52.5078 34ZM22.4462 12.5H23.3111H33.6142C35.5472 12.5 37.1142 14.067 37.1142 16V32.5V34H35.6142H30.752C30.8575 34.4832 30.9131 34.9851 30.9131 35.5C30.9131 35.6681 30.9071 35.8348 30.8955 36H50.3784V39H29.9766C28.7662 41.0923 26.504 42.5 23.9131 42.5C21.3221 42.5 19.0599 41.0923 17.8495 39H10.024V36H16.9306C16.919 35.8348 16.9131 35.6681 16.9131 35.5C16.9131 34.9851 16.9686 34.4832 17.0741 34H11.5H10V32.5V28.121C10 25.9324 11.2976 23.9521 13.3043 23.0783L17.3388 21.3216L22.0129 13.2484L22.4462 12.5ZM34.1142 31H29.2752C27.9911 29.4715 26.0655 28.5 23.9131 28.5C21.6685 28.5 19.6708 29.5564 18.3898 31.1991V31H13V28.121C13 27.1262 13.5898 26.2261 14.5019 25.8289L18.9886 23.8753L19.4408 23.6784L19.6879 23.2516L24.1759 15.5H33.6142C33.8904 15.5 34.1142 15.7239 34.1142 16V31ZM27.9131 35.5C27.9131 37.7091 26.1222 39.5 23.9131 39.5C21.7039 39.5 19.9131 37.7091 19.9131 35.5C19.9131 33.2909 21.7039 31.5 23.9131 31.5C26.1222 31.5 27.9131 33.2909 27.9131 35.5ZM63.3467 35.5C63.3467 37.7091 61.5558 39.5 59.3467 39.5C57.1375 39.5 55.3467 37.7091 55.3467 35.5C55.3467 33.2909 57.1375 31.5 59.3467 31.5C61.5558 31.5 63.3467 33.2909 63.3467 35.5ZM74.0006 39H68.095V36H74.0006V39ZM23.8031 24H30.6929V21H23.8031V24Z"
    fillRule="evenodd"
  />,
  'Van',
  '0 0 84 84',
);
