import createSvgIcon from '../Utils/CreateSvgIcon';

export const Sound = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M17.2064 6.27402C18.2941 7.69257 19 9.71701 19 12.0005C19 14.2839 18.2941 16.3083 17.2064 17.7269L18.7936 18.9438C20.1744 17.1429 21 14.6802 21 12.0005C21 9.32067 20.1744 6.85798 18.7936 5.0571L17.2064 6.27402ZM15 11.9982C15 10.1214 14.6564 9.17635 14.1348 8.27636L15.8652 7.27351C16.5759 8.49984 17 9.7951 17 11.9982C17 14.2013 16.5759 15.4966 15.8652 16.7229L14.1348 15.72C14.6564 14.8201 15 13.875 15 11.9982ZM13 2.5015L6 7.78058L6 7.77493L3 7.77493L3 16.2215L6 16.2215L6 16.2271L13 21.5062L13 2.5015Z"
    fillRule="evenodd"
  />,
  'Sound',
);
