import createSvgIcon from '../Utils/CreateSvgIcon';

export const Eye = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M10.5024 16.8021C7.88901 16.1556 5.61761 14.0183 4.20211 12C5.61761 9.98166 7.88901 7.84442 10.5024 7.19786C11.8916 6.85416 13.4 6.92344 14.9952 7.68203C16.4869 8.39141 18.1055 9.72876 19.7726 12C18.1055 14.2712 16.4869 15.6086 14.9952 16.318C13.4 17.0766 11.8916 17.1458 10.5024 16.8021ZM21.8228 11.4316C19.8791 8.61797 17.8719 6.83542 15.8541 5.87586C13.8194 4.90825 11.8355 4.80774 10.0221 5.25639C6.46303 6.13691 3.65686 9.09778 2.15557 11.4643L1.81573 12L2.15557 12.5357C3.65686 14.9022 6.46303 17.8631 10.0221 18.7436C11.8355 19.1923 13.8194 19.0917 15.8541 18.1241C17.8719 17.1646 19.8791 15.382 21.8228 12.5684L22.2154 12L21.8228 11.4316ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 9.99998 13.1046 9.99998 12C9.99998 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79085 16 7.99998 14.2091 7.99998 12C7.99998 9.79086 9.79085 8 12 8C14.2091 8 16 9.79086 16 12Z"
    fillRule="evenodd"
  />,
  'Eye',
);
