import createSvgIcon from '../Utils/CreateSvgIcon';

export const Instagram = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7ZM18.1579 5.84581C18.1579 6.36902 17.7338 6.79317 17.2106 6.79317C16.6873 6.79317 16.2632 6.36902 16.2632 5.84581C16.2632 5.32259 16.6873 4.89844 17.2106 4.89844C17.7338 4.89844 18.1579 5.32259 18.1579 5.84581ZM15.7896 11.9996C15.7896 14.0925 14.0929 15.7891 12.0001 15.7891C9.90721 15.7891 8.2106 14.0925 8.2106 11.9996C8.2106 9.90672 9.90721 8.21011 12.0001 8.21011C14.0929 8.21011 15.7896 9.90672 15.7896 11.9996ZM17.2106 11.9996C17.2106 14.8773 14.8778 17.2101 12.0001 17.2101C9.12238 17.2101 6.78955 14.8773 6.78955 11.9996C6.78955 9.12189 9.12238 6.78906 12.0001 6.78906C14.8778 6.78906 17.2106 9.12189 17.2106 11.9996Z"
    fillRule="evenodd"
  />,
  'Instagram',
);
