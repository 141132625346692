import createSvgIcon from '../Utils/CreateSvgIcon';

export const Estate = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M43.0822 11.5095C42.034 11.4413 40.9951 11.7418 40.1452 12.359L27.3825 21.6284L27.1174 21.8209L26.7963 21.8854L15.0078 24.2526C13.8401 24.4871 13 25.5127 13 26.7037V32.47H19.1641C20.0097 29.5649 22.646 27.4461 25.7672 27.4461C28.8884 27.4461 31.5247 29.5649 32.3704 32.47H53.5996C54.4452 29.5649 57.0815 27.4461 60.2028 27.4461C62.9542 27.4461 65.3288 29.0925 66.4321 31.4727L71.0022 30.6954V21.9817C71.0022 21.9593 71.0019 21.9371 71.0013 21.9148H62.1719V18.9148H69.0702L63.4444 12.8359L43.0822 11.5095ZM11.5 35.47H18.9484C19.4221 38.8804 22.2945 41.5033 25.7672 41.5033C29.2399 41.5033 32.1123 38.8804 32.586 35.47H53.3839C53.8577 38.8804 56.7301 41.5033 60.2028 41.5033C64.0065 41.5033 67.0901 38.3565 67.0901 34.4747C67.0901 34.4511 67.09 34.4275 67.0898 34.4039L72.7537 33.4405L74.0022 33.2282V31.9618V21.9817C74.0022 20.5966 73.4796 19.2625 72.5388 18.2459L65.2399 10.3591L64.8335 9.92003L64.2365 9.88114L43.2772 8.51587C41.5303 8.40208 39.7987 8.90293 38.3823 9.93164L25.8845 19.0086L14.4172 21.3113C11.8483 21.8271 10 24.0835 10 26.7037V33.97V35.47H11.5ZM29.6546 34.4747C29.6546 36.7573 27.857 38.5033 25.7672 38.5033C23.6774 38.5033 21.8799 36.7573 21.8799 34.4747C21.8799 32.192 23.6774 30.4461 25.7672 30.4461C27.857 30.4461 29.6546 32.192 29.6546 34.4747ZM64.0901 34.4747C64.0901 36.7573 62.2926 38.5033 60.2028 38.5033C58.113 38.5033 56.3154 36.7573 56.3154 34.4747C56.3154 32.192 58.113 30.4461 60.2028 30.4461C62.2926 30.4461 64.0901 32.192 64.0901 34.4747ZM36.501 21.9148H56.501V18.9148H36.501V21.9148Z"
    fillRule="evenodd"
  />,
  'Estate',
  '0 0 84 84',
);
