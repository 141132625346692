/* eslint-disable @typescript-eslint/naming-convention */

import createSvgIcon from '../Utils/CreateSvgIcon';

export const XLBoot = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M10.904 3.84H8.456V16h7.952v-2.016h-5.504V3.84ZM2.895 15v6h18.16v-6h-2v4H4.895v-4h-2Z"
    fillRule="evenodd"
  />,
  'XLBoot',
);
