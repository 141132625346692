import createSvgIcon from '../Utils/CreateSvgIcon';

export const CarLocation = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M18.9584 7.43905C18.9859 7.53158 18.9999 7.6276 18.9999 7.72414V16C18.9999 16.5523 18.5522 17 17.9999 17H14.2666C13.7143 17 13.2666 16.5523 13.2666 16V14H6.73323V16C6.73323 16.5523 6.28552 17 5.73323 17H1.9999C1.44761 17 0.999901 16.5523 0.999901 16V7.72414C0.999901 7.6276 1.01388 7.53158 1.0414 7.43905L1.26612 6.68354L0.019043 5.1247L1.58078 3.8753L1.96029 4.34969L3.0414 0.714908C3.16755 0.290791 3.55742 0 3.9999 0H9.9999H15.9999C16.4424 0 16.8323 0.290791 16.9584 0.714908L18.0531 4.39526L18.469 3.8753L20.0308 5.1247L18.7472 6.72911L18.9584 7.43905ZM4.73323 14H2.9999V12H16.9999V8H2.9999L2.9999 7.8697V15H4.73323V14ZM16.9999 14H15.2666V15H16.9999V14ZM15.254 2L16.4438 6H3.55602L4.74576 2H9.9999H15.254ZM14.9999 10C14.9999 10.5523 14.5522 11 13.9999 11C13.4476 11 12.9999 10.5523 12.9999 10C12.9999 9.44771 13.4476 9 13.9999 9C14.5522 9 14.9999 9.44771 14.9999 10ZM5.9999 11C6.55219 11 6.9999 10.5523 6.9999 10C6.9999 9.44771 6.55219 9 5.9999 9C5.44762 9 4.9999 9.44771 4.9999 10C4.9999 10.5523 5.44762 11 5.9999 11Z"
    fillRule="evenodd"
  />,
  'CarLocation',
  '-2.5 -4 24 24',
);
