import createSvgIcon from '../Utils/CreateSvgIcon';

export const Review = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M17.44 16.5745L16.8006 17.1132L16.7348 17.9467L16.6966 18.4303L15.6192 18.1342L15.0647 17.9818L14.5139 18.1472C13.7267 18.3837 12.8822 18.5128 12 18.5128C7.43339 18.5128 4 15.1213 4 11.2564C4 7.39152 7.43339 4 12 4C16.5666 4 20 7.39152 20 11.2564C20 13.3265 19.0416 15.2254 17.44 16.5745ZM18.4999 21L18.6602 18.9699L18.7286 18.1041C20.7384 16.411 22 13.9701 22 11.2564C22 6.14424 17.5228 2 12 2C6.47715 2 2 6.14424 2 11.2564C2 16.3686 6.47715 20.5128 12 20.5128C13.0781 20.5128 14.1164 20.3549 15.0893 20.0627L16.5363 20.4604L18.4999 21ZM13.1756 9.38197L12 7L10.8244 9.38197L8.19577 9.76393L10.0979 11.618L9.64886 14.2361L12 13L14.3511 14.2361L13.9021 11.618L15.8042 9.76393L13.1756 9.38197Z"
    fillRule="evenodd"
  />,
  'Review',
);
