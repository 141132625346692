import createSvgIcon from '../Utils/CreateSvgIcon';

export const TickSuccess = createSvgIcon(
  <>
    <circle cx="12" cy="12" r="10" transform="rotate(90 12 12)" fill="#FDEEEE" />
    <circle cx="12" cy="12" r="10" fill="#1F8B4D" />
    <path d="M6.5 11.5L10.1667 15L17.5 8" stroke="white" strokeWidth="2" />
  </>,
  'TickSuccess',
);
