import createSvgIcon from '../Utils/CreateSvgIcon';

export const HeartHover = createSvgIcon(
  <path
    className="heart-hover"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.13647 4C5.05428 4 2.55567 6.49861 2.55566 9.5808C2.55566 10.1569 2.64325 10.7142 2.80651 11.2393C3.50893 13.6367 5.57905 15.9475 7.43165 17.5998C8.37807 18.4439 9.30993 19.1533 10.0591 19.6566C10.4324 19.9074 10.7718 20.1148 11.0529 20.2636C11.1923 20.3375 11.3315 20.4045 11.4621 20.4555C11.5607 20.494 11.7674 20.5707 12.0001 20.5707C12.2328 20.5707 12.4395 20.494 12.5381 20.4555C12.6687 20.4045 12.8079 20.3375 12.9474 20.2636C13.2284 20.1148 13.5678 19.9074 13.9411 19.6566C14.6903 19.1533 15.6222 18.4439 16.5686 17.5998C18.4212 15.9475 20.4913 13.6367 21.1937 11.2393C21.357 10.7142 21.4446 10.1569 21.4446 9.5808C21.4445 6.49861 18.9459 4 15.8637 4C14.3637 4 13.0022 4.59232 12.0001 5.55382C10.9981 4.59232 9.63656 4 8.13647 4Z"
    fill="#CCF5ED"
  />,
  'HeartHover',
  '0 0 24 24',
);
