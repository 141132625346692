import createSvgIcon from '../Utils/CreateSvgIcon';

export const LinkedIn = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M17.25 3H6.75C4.67925 3 3 4.67925 3 6.75V17.25C3 19.3208 4.67925 21 6.75 21H17.25C19.3215 21 21 19.3208 21 17.25V6.75C21 4.67925 19.3215 3 17.25 3ZM9 17.25H6.75V9H9V17.25ZM7.875 8.05225C7.1505 8.05225 6.5625 7.45975 6.5625 6.72925C6.5625 5.99875 7.1505 5.40625 7.875 5.40625C8.5995 5.40625 9.1875 5.99875 9.1875 6.72925C9.1875 7.45975 8.60025 8.05225 7.875 8.05225ZM18 17.2502H15.75V13.0472C15.75 10.5212 12.75 10.7125 12.75 13.0472V17.2502H10.5V9.00023H12.75V10.324C13.797 8.38448 18 8.24123 18 12.181V17.2502Z"
    fillRule="evenodd"
  />,
  'LinkedIn',
);
