import createSvgIcon from '../Utils/CreateSvgIcon';

export const Youtube = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M19.8252 4.48939C20.6819 4.753 21.3574 5.52465 21.5879 6.5036C22.0164 8.29168 21.9999 12.0188 21.9999 12.0188C21.9999 12.0188 21.9999 15.727 21.5881 17.5152C21.3574 18.494 20.682 19.2658 19.8252 19.5293C18.2602 20 12 20 12 20C12 20 5.75609 20 4.17467 19.5106C3.3179 19.247 2.64254 18.4752 2.41183 17.4964C2 15.727 2 12 2 12C2 12 2 8.29168 2.41183 6.5036C2.64239 5.52482 3.33438 4.73417 4.17452 4.47073C5.73961 4 11.9998 4 11.9998 4C11.9998 4 18.2602 4 19.8252 4.48939ZM15.3334 12L9.77783 15.3333V8.66667L15.3334 12Z"
    fillRule="evenodd"
  />,
  'Youtube',
);
