import createSvgIcon from '../Utils/CreateSvgIcon';

export const OfferRight = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M19.8069 11.9999L17.1069 5.9999L4.00004 5.9999L4.00004 17.9999L17.1069 17.9999L19.8069 11.9999ZM22 11.9999L18.4 19.9999L4.00004 19.9999L2.00004 19.9999L2.00004 17.9999L2.00004 5.9999L2.00004 3.9999L4.00004 3.9999L18.4 3.9999L22 11.9999ZM7.46324 12.0664C8.26344 12.2808 9.08595 11.806 9.30036 11.0058C9.51477 10.2056 9.0399 9.38306 8.2397 9.16865C7.4395 8.95424 6.617 9.42911 6.40258 10.2293C6.18817 11.0295 6.66304 11.852 7.46324 12.0664ZM15.803 13.7836C15.5886 14.5838 14.7661 15.0587 13.9659 14.8443C13.1657 14.6298 12.6909 13.8073 12.9053 13.0071C13.1197 12.2069 13.9422 11.7321 14.7424 11.9465C15.5426 12.1609 16.0175 12.9834 15.803 13.7836ZM11.6871 9.0939L8.68705 14.29L10.4191 15.29L13.4191 10.0939L11.6871 9.0939Z"
    fillRule="evenodd"
  />,
  'OfferRight',
);
