import createSvgIcon from '../Utils/CreateSvgIcon';

export const EmissionSticker1 = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.0418" cy="12.0419" r="12.0418" fill="#8E4994" />
    <circle cx="12" cy="12" r="8.5" fill="white" />
    <circle cx="12" cy="12" r="8" fill="#A864A1" />
    <path
      d="M11.7574 9.411L9.63436 10.357L9.38136 9.004L12.0764 7.816L13.3084 7.816L13.3084 16H11.7574L11.7574 9.411Z"
      fill="white"
    />
  </svg>,
  'EmissionSticker1',
);
