import createSvgIcon from '../Utils/CreateSvgIcon';

export const EmissionSticker2 = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.0418" cy="12.042" r="12.0418" fill="#ECBD36" />
    <circle cx="12" cy="12" r="8.5" fill="white" />
    <circle cx="12" cy="12" r="8" fill="#F1D034" />
    <path
      d="M9.27136 16V14.79C10.1587 13.9907 10.8664 13.2867 11.3944 12.678C11.9224 12.0693 12.2854 11.5853 12.4834 11.226C12.6814 10.8593 12.7804 10.5 12.7804 10.148C12.7804 9.78867 12.6704 9.50633 12.4504 9.301C12.2304 9.08833 11.937 8.982 11.5704 8.982C11.2184 8.982 10.8737 9.06633 10.5364 9.235C10.199 9.39633 9.89102 9.62733 9.61236 9.928L9.33736 8.597C9.63802 8.29633 10.0084 8.06167 10.4484 7.893C10.8884 7.72433 11.3467 7.64 11.8234 7.64C12.608 7.64 13.2314 7.849 13.6934 8.267C14.1627 8.685 14.3974 9.246 14.3974 9.95C14.3974 10.478 14.2617 11.0133 13.9904 11.556C13.7264 12.0913 13.301 12.689 12.7144 13.349C12.465 13.6277 12.0434 14.0493 11.4494 14.614L14.6394 14.614V16L9.27136 16Z"
      fill="white"
    />
  </svg>,
  'EmissionSticker2',
);
