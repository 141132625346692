import createSvgIcon from '../Utils/CreateSvgIcon';

export const Coupe = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M35.8778 8.5L36.7254 8.57706L61.815 10.858L70.1782 11.6183L69.9066 14.606L64.4606 14.1109L66.1829 17.0263L70.8855 19.2874C72.7906 20.2035 74.0022 22.1303 74.0022 24.2442V32.3736V33.4445L72.9894 33.7923L67.9908 35.5091C67.4752 38.9029 64.6251 41.4992 61.1864 41.4992C57.8814 41.4992 55.1201 39.1008 54.4526 35.9012H30.5335C29.866 39.1008 27.1047 41.4992 23.7997 41.4992C20.4947 41.4992 17.7334 39.1008 17.0659 35.9012H11.5H10V34.4012V28.6557C10 25.3867 12.1173 22.4943 15.2335 21.5064L29.7633 16.9L35.3769 9.18814L35.8778 8.5ZM71.0022 31.3028L67.7969 32.4037C66.959 29.4579 64.3165 27.3066 61.1864 27.3066C57.8826 27.3066 55.1221 29.7032 54.4534 32.9012H30.5327C29.864 29.7032 27.1035 27.3066 23.7997 27.3066C20.4959 27.3066 17.7354 29.7032 17.0666 32.9012H13V28.6557C13 26.6943 14.2704 24.9588 16.1401 24.3661L31.1395 19.6109L31.609 19.462L31.8989 19.0638L37.3014 11.6418L60.7784 13.7762L63.8314 18.944L64.0628 19.3357L64.4729 19.5328L69.5855 21.9911C70.4515 22.4075 71.0022 23.2833 71.0022 24.2442V31.3028ZM27.6873 34.4029C27.6873 36.7503 25.8629 38.4992 23.7997 38.4992C21.7364 38.4992 19.9121 36.7503 19.9121 34.4029C19.9121 32.0555 21.7364 30.3066 23.7997 30.3066C25.8629 30.3066 27.6873 32.0555 27.6873 34.4029ZM65.074 34.4029C65.074 36.7503 63.2496 38.4992 61.1864 38.4992C59.1232 38.4992 57.2988 36.7503 57.2988 34.4029C57.2988 32.0555 59.1232 30.3066 61.1864 30.3066C63.2496 30.3066 65.074 32.0555 65.074 34.4029ZM40.5254 21.7091L56.2679 21.7091V18.7091L40.5254 18.7091V21.7091Z"
    fillRule="evenodd"
  />,
  'Coupe',
  '0 0 84 84',
);
