import createSvgIcon from '../Utils/CreateSvgIcon';

export const Whatsapp = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M5 16.3641V15.904L4.79895 15.4902C4.28772 14.4378 4 13.2553 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C10.1964 20 8.53738 19.4055 7.20037 18.401L6.66663 18H5.99903H5V16.3641ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5646 2.35934 15.0454 3 16.3641V18V20H5H5.99903C7.67051 21.2558 9.74835 22 12 22ZM14.6745 13.2844C14.8546 13.0444 15.0012 13.0822 15.2228 13.1644L17.3566 14.1592C17.6352 14.2913 17.465 14.7584 17.21 15.3069C16.9484 15.8716 16.0351 16.3822 15.5675 16.4563C15.1479 16.5151 14.6175 16.5409 14.0358 16.3589C13.9806 16.34 13.923 16.3206 13.8628 16.3003C13.5378 16.1907 13.139 16.0562 12.6475 15.8522C10.3829 14.8876 8.84721 12.7359 8.54103 12.3069C8.5174 12.2738 8.5011 12.251 8.49234 12.2397C8.4867 12.2319 8.47945 12.2219 8.47074 12.21C8.29192 11.9649 7.5 10.8796 7.5 9.76212C7.5 8.59017 8.08172 7.95949 8.35058 7.7694C8.90297 7.37714 9.8008 7.40614 10.0126 7.90311C10.0968 8.09926 10.2299 8.41753 10.3612 8.73152C10.5464 9.17441 10.7281 9.6088 10.7638 9.67997C10.8249 9.79917 10.8656 9.93208 10.785 10.1012C10.7679 10.1331 10.7526 10.1625 10.7382 10.1903C10.6812 10.3001 10.6371 10.385 10.5422 10.5007C10.5094 10.5392 10.4757 10.5796 10.4418 10.6202C10.3495 10.7307 10.256 10.8427 10.1756 10.9228C10.0534 11.0428 9.92708 11.1757 10.0697 11.4174C10.2131 11.6598 10.6978 12.4443 11.4213 13.0847C12.1971 13.771 12.8714 14.0607 13.2153 14.2085C13.2835 14.2378 13.3387 14.2615 13.3791 14.2816C13.6211 14.3895 13.7628 14.3782 13.9062 14.2212C14.0472 14.0609 14.5026 13.5148 14.6745 13.2844Z"
    fillRule="evenodd"
  />,
  'Whatsapp',
);
