import createSvgIcon from '../Utils/CreateSvgIcon';

export const ChevronTop = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M12.0711 10.4142L5.70718 16.7782L4.29297 15.364L12.0711 7.58578L19.8493 15.364L18.4351 16.7782L12.0711 10.4142Z"
    fillRule="evenodd"
  />,
  'ChevronTop',
);
