import createSvgIcon from '../Utils/CreateSvgIcon';

export const Sort = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M19.5976 16.0669L18.5 17.1712L18.5 3L16.5 3L16.5 17.1636L15.4099 16.0669L14.0001 17.4854L16.7988 20.3013L17.5038 21.0106L18.2087 20.3013L21.0075 17.4854L19.5976 16.0669ZM5.50002 21L5.50002 6.84633L4.41051 7.94154L3 6.52363L5.50002 4.01052V4H5.51048L5.8 3.70896L6.50526 3.00001L7.21051 3.70896L7.50002 4L10.0105 6.52363L8.6 7.94154L7.50002 6.83579L7.50002 21H5.50002Z"
    fillRule="evenodd"
  />,
  'Sort',
);
