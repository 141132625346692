/* eslint-disable @typescript-eslint/naming-convention */

import createSvgIcon from '../Utils/CreateSvgIcon';

export const MBG = createSvgIcon(
  <path
    d="M17.6057 7.34376C20.369 10.107 20.369 14.5872 17.6057 17.3505C14.8424 20.1138 10.3623 20.1138 7.59898 17.3505C6.89567 16.6472 6.37137 15.8327 6.02608 14.9636H3.90999C4.32743 16.3548 5.08569 17.6656 6.18476 18.7647C9.72909 22.309 15.4756 22.309 19.0199 18.7647C22.5643 15.2204 22.5643 9.47388 19.0199 5.92955C15.4756 2.38522 9.72909 2.38521 6.18476 5.92955C5.82588 6.28843 5.50334 6.66989 5.21713 7.06935L4.90911 5.82847L2.96802 6.31031L3.87543 9.96584L4.11635 10.9364L5.0869 10.6955L8.74243 9.78805L8.26059 7.84696L6.87443 8.19105C7.09007 7.89462 7.33159 7.61115 7.59898 7.34376C10.3623 4.58048 14.8424 4.58048 17.6057 7.34376ZM10.5199 14.24H9.56989V16H15.3399V14.24H12.6399V13.13H14.4399V11.48H12.6399V11.4C12.6399 10.5867 13.0332 10.18 13.8199 10.18C14.2266 10.18 14.6499 10.29 15.0899 10.51L15.3199 8.8C15.0999 8.67333 14.8299 8.57667 14.5099 8.51C14.1966 8.43667 13.8699 8.4 13.5299 8.4C12.5699 8.4 11.8266 8.65667 11.2999 9.17C10.7799 9.68333 10.5199 10.4167 10.5199 11.37V11.48H9.61989L9.61989 13.13H10.5199V14.24Z"
    fillRule="evenodd"
  />,
  'MBG',
);
