import createSvgIcon from '../Utils/CreateSvgIcon';

export const Seats = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M19.0087 5C19.0087 5.55228 18.561 6 18.0087 6C17.4564 6 17.0087 5.55228 17.0087 5C17.0087 4.44772 17.4564 4 18.0087 4C18.561 4 19.0087 4.44772 19.0087 5ZM21.0087 5C21.0087 6.65685 19.6655 8 18.0087 8C16.3518 8 15.0087 6.65685 15.0087 5C15.0087 3.34315 16.3518 2 18.0087 2C19.6655 2 21.0087 3.34315 21.0087 5ZM17.8446 15L14.974 15L15.7545 11.2927C15.9132 10.5392 16.6613 10.0642 17.4107 10.2413C18.1229 10.4096 18.5772 11.1078 18.4426 11.8272L17.8487 15L17.8446 15ZM19.509 17.0006L20.4085 12.1951C20.7396 10.426 19.6223 8.70885 17.8707 8.29494C16.0276 7.85941 14.1876 9.02748 13.7974 10.8807L12.9302 15L5.00006 15L3.00006 15L3.00006 17L3.00006 19L3.00006 21L5.00006 21L15.4378 21C17.3646 21 19.0176 19.6264 19.3703 17.7322L19.5065 17.0006L19.509 17.0006ZM5.00006 17L12.5092 17L14.5529 17.0006L17.4721 17.0006L17.404 17.3661C17.2277 18.3132 16.4012 19 15.4378 19L5.00006 19L5.00006 17Z"
    fillRule="evenodd"
  />,
  'Seats',
);
