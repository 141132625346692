import createSvgIcon from '../Utils/CreateSvgIcon';

export const HeartActive = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 9.06217C2.00001 5.71412 4.71438 3 8.06273 3C9.69236 3 11.1714 3.64341 12.26 4.68784C13.3486 3.64341 14.8276 3 16.4573 3C19.8056 3 22.52 5.71412 22.52 9.06217C22.52 9.68792 22.4248 10.2933 22.2475 10.8638C21.4844 13.468 19.2355 15.978 17.223 17.7728C16.1948 18.6898 15.1825 19.4603 14.3686 20.007C13.963 20.2795 13.5944 20.5048 13.289 20.6664C13.1376 20.7467 12.9863 20.8194 12.8444 20.8749C12.7374 20.9167 12.5128 21 12.26 21C12.0072 21 11.7826 20.9167 11.6756 20.8749C11.5337 20.8194 11.3824 20.7467 11.231 20.6664C10.9256 20.5048 10.557 20.2795 10.1514 20.007C9.33752 19.4603 8.32518 18.6898 7.29704 17.7728C5.28447 15.978 3.03558 13.468 2.2725 10.8638C2.09515 10.2933 2 9.68792 2 9.06217Z"
    fill="#00CEA5"
  />,
  'HeartActive',
  '0 0 24 24',
);
