import createSvgIcon from '../Utils/CreateSvgIcon';

export const Pickup = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M22.4421 8.5H23.307H37.0175C40.0551 8.5 42.5175 10.9624 42.5175 14V31.5V33H41.0175H30.529C30.6328 33.4832 30.6875 33.9851 30.6875 34.5C30.6875 34.6681 30.6817 34.8348 30.6702 35H50.3651V38H29.766C28.5751 40.0923 26.3492 41.5 23.7998 41.5C21.2504 41.5 19.0245 40.0923 17.8336 38H10.0245V35H16.9295C16.918 34.8348 16.9122 34.6681 16.9122 34.5C16.9122 33.9851 16.9669 33.4832 17.0707 33H11.5H10V31.5V24.1207C10 21.9323 11.2973 19.9522 13.3036 19.0783L17.3362 17.3217L22.0088 9.24861L22.4421 8.5ZM39.5175 30H29.0759C27.8124 28.4715 25.9178 27.5 23.7998 27.5C21.6043 27.5 19.6487 28.544 18.3874 30.1703V30H13V24.1207C13 23.126 13.5897 22.2259 14.5017 21.8287L18.9864 19.8752L19.4386 19.6783L19.6857 19.2514L24.172 11.5H37.0175C38.3982 11.5 39.5175 12.6193 39.5175 14V30ZM27.6875 34.5C27.6875 36.7551 25.9013 38.5 23.7998 38.5C21.6983 38.5 19.9122 36.7551 19.9122 34.5C19.9122 32.2449 21.6983 30.5 23.7998 30.5C25.9013 30.5 27.6875 32.2449 27.6875 34.5ZM63.1077 34.5C63.1077 36.7551 61.3215 38.5 59.22 38.5C57.1185 38.5 55.3324 36.7551 55.3324 34.5C55.3324 32.2449 57.1185 30.5 59.22 30.5C61.3215 30.5 63.1077 32.2449 63.1077 34.5ZM66.1077 34.5C66.1077 38.366 63.024 41.5 59.22 41.5C55.4161 41.5 52.3324 38.366 52.3324 34.5C52.3324 33.9851 52.3871 33.4832 52.4909 33H46.4284H44.9284V31.5V20V18.5H46.4284H68.5022C71.5397 18.5 74.0022 20.9624 74.0022 24V31.5V33H72.5022H65.9492C66.053 33.4832 66.1077 33.9851 66.1077 34.5ZM64.4961 30H71.0022V24C71.0022 22.6193 69.8829 21.5 68.5022 21.5H47.9284V30H53.944C55.2074 28.4715 57.1021 27.5 59.22 27.5C61.338 27.5 63.2326 28.4715 64.4961 30ZM73.9791 38H68.0756V35H73.9791V38ZM23.7989 21H36.5898V18H23.7989V21Z"
    fillRule="evenodd"
  />,
  'Pickup',
  '0 0 84 84',
);
