import createSvgIcon from '../Utils/CreateSvgIcon';

export const Netherlands = createSvgIcon(
  <>
    <path d="M0 0h20v5.333H0z" fill="#C7270A" />
    <path d="M0 5.333h20v5.333H0z" fill="#fff" />
    <path d="M0 10.667h20V16H0z" fill="#052962" />
  </>,
  'Netherlands',
  '-2 -4 24 24',
);
