import createSvgIcon from '../Utils/CreateSvgIcon';

export const BodyColorOther = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" fill="url(#paint0_linear_1304_12033)" />
    <defs>
      <linearGradient
        id="paint0_linear_1304_12033"
        x1="12"
        y1="2"
        x2="12"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFFF36" />
        <stop offset="0.275" stopColor="#D030C0" stopOpacity="0.5" />
        <stop offset="0.5875" stopColor="#EF7200" stopOpacity="0.715517" />
        <stop offset="1" stopColor="#00C02A" />
      </linearGradient>
    </defs>
  </svg>,
  'BodyColorOther',
);
