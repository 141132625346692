/* eslint-disable @typescript-eslint/naming-convention */

import createSvgIcon from '../Utils/CreateSvgIcon';

export const MVP = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M21.8372 10.8614C22.8813 9.67792 24.3834 9 25.9615 9H70.4993C72.4323 9 73.9993 10.567 73.9993 12.5V34.5V36H72.4993H68.2504C67.5635 39.1452 64.7618 41.5 61.4099 41.5C58.0579 41.5 55.2562 39.1452 54.5693 36H42.5989H31.0634C30.3765 39.1452 27.5748 41.5 24.2228 41.5C20.8709 41.5 18.0692 39.1452 17.3823 36H12.6985H11.3685L11.2093 34.6796L10.0398 24.9824C9.76109 22.6719 10.9668 20.4361 13.0504 19.3996L15.2854 18.2877L21.8372 10.8614ZM54.5693 33C55.2562 29.8548 58.0579 27.5 61.4099 27.5C64.7618 27.5 67.5635 29.8548 68.2504 33H70.9993V12.5C70.9993 12.2239 70.7754 12 70.4993 12H25.9615C25.2442 12 24.5614 12.3081 24.0868 12.8461L19.5399 18H33.3021V21H16.5687L14.3867 22.0855C13.4395 22.5567 12.8915 23.573 13.0182 24.6232L14.0285 33H17.3823C18.0692 29.8548 20.8709 27.5 24.2228 27.5C27.5748 27.5 30.3765 29.8548 31.0634 33H42.5989H54.5693ZM67.4741 21H39.3326V18H67.4741V21ZM24.2228 38.5C26.4336 38.5 28.2245 36.7085 28.2245 34.5C28.2245 32.2915 26.4336 30.5 24.2228 30.5C22.0121 30.5 20.2212 32.2915 20.2212 34.5C20.2212 36.7085 22.0121 38.5 24.2228 38.5ZM65.4115 34.5C65.4115 36.7085 63.6206 38.5 61.4099 38.5C59.1991 38.5 57.4082 36.7085 57.4082 34.5C57.4082 32.2915 59.1991 30.5 61.4099 30.5C63.6206 30.5 65.4115 32.2915 65.4115 34.5Z"
    fillRule="evenodd"
  />,
  'MVP',
  '0 0 84 84',
);
