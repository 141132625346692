import createSvgIcon from '../Utils/CreateSvgIcon';

export const Intercom = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M20.5 21.5C20.5 21.5 17.6381 20.3986 15.3228 19.5164H5.58705C4.43453 19.5164 3.5 18.5474 3.5 17.3523V4.6641C3.5 3.469 4.43453 2.5 5.58705 2.5H18.4123C19.5649 2.5 20.4994 3.469 20.4994 4.6641V15.5916H20.5V21.5ZM17.9874 14.3894C17.7868 14.1474 17.4365 14.1194 17.2029 14.3267C17.1847 14.3426 15.3721 15.9189 11.9995 15.9189C8.66888 15.9189 6.82836 14.354 6.79557 14.3255C6.56193 14.1192 6.21262 14.1474 6.01225 14.3888C5.91604 14.5048 5.86827 14.6562 5.87956 14.8091C5.89085 14.9621 5.96026 15.104 6.07236 15.2033C6.15859 15.2793 8.22013 17.0735 11.9995 17.0735C15.7795 17.0735 17.8411 15.2793 17.9273 15.2033C18.0392 15.104 18.1085 14.9622 18.1198 14.8094C18.1311 14.6566 18.0834 14.5054 17.9874 14.3894Z"
    fillRule="evenodd"
  />,
  'Intercom',
);
