import createSvgIcon from '../Utils/CreateSvgIcon';

export const Lock = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M19 9L18 9L16 9L8 9L6 9L5 9L5 19L19 19L19 9ZM19 7L18 7L18 6C18 4.34315 16.6569 3 15 3L9 3C7.34315 3 6 4.34315 6 6L6 7L5 7L3 7L3 9L3 19L3 21L5 21L19 21L21 21L21 19L21 9L21 7L19 7ZM16 6L16 7L8 7L8 6C8 5.44772 8.44771 5 9 5L15 5C15.5523 5 16 5.44771 16 6ZM14 13C14 13.7403 13.5978 14.3866 13 14.7324L13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 14.7324C10.4022 14.3866 10 13.7403 10 13C10 11.8954 10.8954 11 12 11C13.1046 11 14 11.8954 14 13Z"
    fillRule="evenodd"
  />,
  'Lock',
);
