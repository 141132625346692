import createSvgIcon from '../Utils/CreateSvgIcon';

export const Swap = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M20.5815 7.95718L18.7886 6.16428L18.7886 15.711C18.7886 18.4724 16.55 20.711 13.7886 20.711L13.7886 18.711C15.4455 18.711 16.7886 17.3679 16.7886 15.711L16.7886 6.09322L14.9247 7.95718L13.5104 6.54296L17.046 3.00743L17.7531 2.30032L18.4602 3.00743L21.9957 6.54296L20.5815 7.95718ZM6.78862 7.71101V17.9069L9.15258 15.543L10.5668 16.9572L7.03126 20.4927L6.32416 21.1998L5.61705 20.4927L4.83535 19.711H4.78862V19.6643L2.08152 16.9572L3.49573 15.543L4.78862 16.8359V7.71101C4.78862 4.94959 7.0272 2.71101 9.78862 2.71101L9.78862 4.71101C8.13177 4.71101 6.78862 6.05415 6.78862 7.71101Z"
    fillRule="evenodd"
  />,
  'Swap',
);
