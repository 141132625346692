import createSvgIcon from '../Utils/CreateSvgIcon';

export const CallBack = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M2.92407 5.79728C3.51186 4.89844 4.20526 4.16826 5.07108 3.5231C6.2844 2.619 7.86475 2.97043 8.77439 3.93964L10.401 5.67278C11.4364 6.77597 11.4883 8.47752 10.522 9.64175L9.66203 10.678C10.3238 11.7315 10.8163 12.3682 11.3813 12.8932C11.9531 13.4245 12.6401 13.8803 13.7521 14.5241L14.4695 13.6701C15.5619 12.3698 17.5149 12.2347 18.7759 13.3722L20.1492 14.6111C21.2877 15.638 21.4686 17.3581 20.5687 18.5994L20.1131 19.2279C19.0253 20.7286 17.1001 21.6191 15.1755 21.0401C11.351 19.8896 8.20825 17.6641 6.01361 15.1379C3.83679 12.6321 2.51536 9.73621 2.50225 7.20771C2.49959 6.69436 2.65657 6.20634 2.92407 5.79728ZM6.26609 5.12683C5.5829 5.63591 5.05343 6.19535 4.59794 6.89188C4.53151 6.99347 4.50173 7.10056 4.50223 7.19735C4.51204 9.09196 5.54064 11.5438 7.52344 13.8262C9.48842 16.0881 12.3149 18.0911 15.7517 19.1249C16.7166 19.4152 17.8107 18.9965 18.4939 18.0541L18.9495 17.4256C19.2494 17.0118 19.1891 16.4385 18.8096 16.0961L17.4363 14.8573C17.0159 14.4781 16.3649 14.5232 16.0008 14.9566L15.281 15.8135C14.6627 16.5494 13.5995 16.7466 12.7576 16.2593C11.615 15.598 10.7645 15.0502 10.0199 14.3583C9.26936 13.6609 8.6674 12.8558 7.9602 11.7287C7.49945 10.9944 7.57776 10.0577 8.119 9.40553L8.98304 8.36446C9.30512 7.97638 9.28783 7.4092 8.94269 7.04147L7.31608 5.30833C6.97297 4.94276 6.52065 4.93715 6.26609 5.12683ZM15.9824 9.44007L19.8152 5.60723L20.5223 4.90012L19.108 3.48591L18.4009 4.19303L14.6379 7.95608V6.4781L12.638 6.49032V10.4605V11.4605L13.638 11.4544L17.6081 11.4301V9.43014L15.9824 9.44007Z"
    fillRule="evenodd"
  />,
  'CallBack',
);
