import createSvgIcon from '../Utils/CreateSvgIcon';

export const RegularBoot = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M9.704 15.984c.768.181 1.53.272 2.288.272 1.44 0 2.57-.32 3.392-.96.832-.65 1.248-1.536 1.248-2.656 0-.95-.304-1.717-.912-2.304-.608-.597-1.637-1.125-3.088-1.584a10.638 10.638 0 0 1-1.312-.496c-.299-.15-.517-.315-.656-.496-.128-.192-.192-.432-.192-.72 0-.459.17-.81.512-1.056.352-.245.87-.368 1.552-.368.523 0 1.072.085 1.648.256.587.16 1.11.373 1.568.64l.336-2.032a6.735 6.735 0 0 0-1.712-.656c-.65-.16-1.29-.24-1.92-.24-.885 0-1.659.15-2.32.448-.661.288-1.168.693-1.52 1.216-.341.523-.512 1.125-.512 1.808 0 .95.283 1.712.848 2.288.565.576 1.51 1.072 2.832 1.488.939.299 1.573.576 1.904.832.341.256.512.592.512 1.008 0 .49-.203.875-.608 1.152-.395.267-.96.4-1.696.4-.63 0-1.285-.096-1.968-.288a6.551 6.551 0 0 1-1.792-.784l-.24 2.096c.437.299 1.04.544 1.808.736ZM2.895 15v6h18.16v-6h-2v4H4.895v-4h-2Z"
    fillRule="evenodd"
  />,
  'RegularBoot',
);
