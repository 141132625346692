import createSvgIcon from '../Utils/CreateSvgIcon';

export const Convertible = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M30.6472 16.4408L11.0505 22.5959L10 22.9258V24.0269V33.5119V35.0119H11.5H16.9288C17.1822 38.6377 20.1635 41.4994 23.8039 41.4994C27.4443 41.4994 30.4255 38.6377 30.6789 35.0119H53.3334C53.5869 38.6377 56.5681 41.4994 60.2085 41.4994C63.8764 41.4994 66.8752 38.5943 67.0889 34.9295L72.9827 32.936L74.0021 32.5912V31.5151C74.0021 28.0487 73.2135 24.9945 71.5979 22.5709C70.1727 20.433 68.1532 18.8619 65.6386 17.9414V13.0473H62.6386V17.463L35.1218 16.1742L40.6094 10.6057L38.4726 8.5L30.6472 16.4408ZM66.6134 31.9234L70.9725 30.4489C70.829 27.914 70.1688 25.8356 69.1017 24.235C67.9268 22.4726 66.1992 21.1971 63.8957 20.5251L32.3576 19.048L13 25.1281V32.0119H17.3649C18.3565 29.3861 20.8655 27.5216 23.8039 27.5216C26.7423 27.5216 29.2512 29.3861 30.2429 32.0119H53.7695C54.7612 29.3861 57.2701 27.5216 60.2085 27.5216C63.1138 27.5216 65.5993 29.3443 66.6134 31.9234ZM27.6964 34.5105C27.6964 36.7529 25.9145 38.4994 23.8039 38.4994C21.6932 38.4994 19.9113 36.7529 19.9113 34.5105C19.9113 32.2681 21.6932 30.5216 23.8039 30.5216C25.9145 30.5216 27.6964 32.2681 27.6964 34.5105ZM64.1011 34.5105C64.1011 36.7529 62.3192 38.4994 60.2085 38.4994C58.0978 38.4994 56.316 36.7529 56.316 34.5105C56.316 32.2681 58.0978 30.5216 60.2085 30.5216C62.3192 30.5216 64.1011 32.2681 64.1011 34.5105Z"
    fillRule="evenodd"
  />,
  'Convertible',
  '0 0 84 84',
);
