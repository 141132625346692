/* eslint-disable @typescript-eslint/naming-convention */

import createSvgIcon from '../Utils/CreateSvgIcon';

export const USB = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M18.5 6L16.5 6L16.5 8L16.5 10L18.5 10L20.5 10L20.5 8L20.5 6L18.5 6ZM17.5 12.1422L17.5 10.0002L19.5 10.0002L19.5 13.0002L19.5 13.8581L18.6521 13.9885L13 14.8581L13 16.1709C14.1652 16.5827 15 17.6939 15 19.0002C15 20.657 13.6569 22.0002 12 22.0002C10.3431 22.0002 9 20.657 9 19.0002C9 17.6939 9.83481 16.5827 11 16.1709L11 15.8473L5.8356 14.9865L5 14.8473L5 14.0002L5 11.792C4.11705 11.4062 3.5 10.5252 3.5 9.5C3.5 8.11929 4.61929 7 6 7C7.38071 7 8.5 8.11929 8.5 9.5C8.5 10.5252 7.88295 11.4062 7 11.792L7 13.153L11 13.8197L11 5.41436L9.70711 6.70726L8.29289 5.29304L11.2929 2.29304L12 1.58594L12.7071 2.29304L15.7071 5.29304L14.2929 6.70726L13 5.41436L13 12.8345L17.5 12.1422ZM13 19.0002C13 19.5524 12.5523 20.0002 12 20.0002C11.4477 20.0002 11 19.5524 11 19.0002C11 18.4479 11.4477 18.0002 12 18.0002C12.5523 18.0002 13 18.4479 13 19.0002Z"
    fillRule="evenodd"
  />,
  'USB',
);
